import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

import { of, Observable } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';

import { environment } from 'src/environments/environment';
import { AuthResponse, User } from '../interfaces/interfaces';

@Injectable({
  providedIn: 'root'
})
export class AuthServiceService {

  private api_url: string = environment.api_url;
  private _user!: User;

  get user() {
    return { ...this._user };
  }

  constructor( private http: HttpClient,private router: Router) { }
  //get token localStorage
  get token(): string {
    return localStorage.getItem('x-cms-mojve') || '';
  }

  //get name localStorage
  get userName(): String {
    return localStorage.getItem('x-cms-mojve-user') || '';

  }
  // get headers 
  get headers() {
    return {
      headers: {
        'x-cms-mojve': this.token
      }
    }
  }



  login( email: string, password: string ) {

    const url  = `${ this.api_url }/auth`;
    const body = { email, password };

    return this.http.post( url, body )
        .pipe(
          tap( (resp: any) => {
           
            if ( resp.success ) {
              localStorage.setItem('x-cms-mojve', resp.token! );
              localStorage.setItem('x-cms-mojve-user', resp.account.name! );
            } 
          })
      );
        /*
        tap( resp:any => {
          if ( resp.success ) {
            localStorage.setItem('x-cms-mojve', resp.token! );
            localStorage.setItem('x-cms-mojve-user', resp.account.name! );
          }
        }),
        */
        //map( resp => resp ),
        //catchError( err => of(err.error.msg) )
 
  }

  validarToken(): Observable<boolean> {

    const url = `${ this.api_url }/auth/renewToken`;
    
    return this.http.get<any>( url, this.headers )
        .pipe(
          map( resp => {
            if (resp.token !=false){
                localStorage.setItem('x-cms-mojve', resp.token! );
            }
            return resp.success;
          }),
          catchError( err => of(false) )
        );
  }

  logout() {
    localStorage.clear();
    this.router.navigateByUrl('/');
  }

}
