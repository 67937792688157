import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router, CanLoad, Route } from '@angular/router';

import { tap } from 'rxjs/operators';
import { AuthServiceService } from 'src/app/auth/services/auth-service.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate, CanLoad {

  constructor( private authService: AuthServiceService,
               private router: Router) {}

  canLoad(route: Route, segments: import("@angular/router").UrlSegment[]): boolean | import("@angular/router").UrlTree | import("rxjs").Observable<boolean | import("@angular/router").UrlTree> | Promise<boolean | import("@angular/router").UrlTree> {
    return this.authService.validarToken()
        .pipe(
          tap( isAuthenticated =>  {
            if ( !isAuthenticated ) {
              this.router.navigateByUrl('/');
            }
          })
        );
  }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot) {

      return this.authService.validarToken()
        .pipe(
          tap( isAuthenticated =>  {
            console.log(isAuthenticated);
            if ( !isAuthenticated ) {
              this.router.navigateByUrl('/');
            }
          })
        );
  }
  
}
