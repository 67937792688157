import { MenuItem } from './menu.model';

export const MENU_CMS: MenuItem[] = [
    {
        id: 1,
        label: 'MENUITEMS.MENU.TEXT',
        isTitle: true
    },
    {
      id: 1,
      label: 'MENUITEMS.TEMPLATES.TEXT',
      icon: 'bx-file',
      link: '/platform/templates',
    },
    /*
    {
      id: 2,
      label: 'MENUITEMS.WIDGETS.TEXT',
      icon: 'bxs-eraser',
      link: '/platform/widgets',
    },
    */
       /*
    {
      id: 3,
      label: 'MENUITEMS.USERS.TEXT',
      icon: 'bx-user-circle',
      link: '/platform/accounts',
    },
    */
   /*
    {
      id: 4,
      label: 'MENUITEMS.VERSIONS.TEXT',
      icon: 'bx-calendar',
      link: '/platform/versions',
    },
    */
    {
      id: 5,
      label: 'MENUITEMS.PROJECTS.TEXT',
      icon: 'bx bxl-git',
      link:  '/platform/projects',
    },
    {
      id: 6,
      label: 'MENUITEMS.PHOTOGRAPHERS.TEXT',
      icon: 'bx bx-camera',
      link:  '/platform/photographers',
    },
    {
      id: 7,
      label: 'MENUITEMS.PHOTOGRAPHY-PROJECTS.TEXT',
      icon: 'bx bx-photo-album',
      link:  '/platform/photography-projects',
    },
    {
      id: 8,
      label: 'MENUITEMS.ABOUT.TEXT',
      icon: 'bx bx-text',
      link:  '/platform/about',
    },

    
  
];

